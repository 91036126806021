<template>
  <div>
    <edit-brand-profile :id="$route.params.id" :readonly="readonly" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EditBrandProfile from './EditBrandProfile.vue'

export default {
  components: {
    EditBrandProfile
  },

  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },

  readonly: function () {
    let readonly = true

    if (this.user && (this.user.role === 'admin' || this.user.organization)) {
      this.readonly = false
    }

    return readonly
  }
}
</script>